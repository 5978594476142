import { useState, useContext, useRef } from "react";

import { Link } from "react-router-dom";

import { Popover } from "@headlessui/react";

import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";

import { ApiContext } from "../providers/ApiProvider";

import Footer from "../components/ui/Footer";
import { toast } from "sonner";

const features = [
  {
    name: "Multi-Media Notes",
    description: "Capture all your health information your way, including voice notes and images",
  },
  {
    name: "Track Symptoms, Labs & Vital Signs",
    description: "Easily track all your data in one place; see historical trends",
  },
  {
    name: "Find What You Need",
    description: "Search through your records to find the information you need",
  },
  {
    name: "Share Your Way",
    description: "Manage information for your loved ones, not just your own",
  },
  {
    name: "Bring Your Data With You",
    description: "Integrate data from wearables and other health platforms, like MyChart",
  },
  {
    name: "Secure",
    description: "We use latest industry-standard security and your data is stored in a HIPAA-compliant manner",
  },
];

const LandingPage = () => {
  const api = useContext(ApiContext);

  const emailInputRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const navItems = (
    <>
      <li className="nav-item">
        <a href="https://mapper.caspiahealth.com/">Lifestyle Medicine Mapper</a>
      </li>
      <li className="nav-item">
        <Link to="/newsletter">Healthy Today Newsletter</Link>
      </li>
      <li className="nav-item">
        <a href="https://propatienttech.com/">For Physicians</a>
      </li>
      <li className="nav-item">
        <Link to="/login">Sign In</Link>
      </li>
    </>
  );

  function handleSubmit(e, email) {
    e.preventDefault();

    api.client
      .post("/email_signups", { email: email, patient: true })
      .then(() => {
        toast.success("Thanks for your interest", { description: "We'll follow up soon with more details." });
      })
      .catch((error) => {
        if (error.response.data.errors.includes("Email has already been taken")) {
          toast.error("Looks like you're already signed up", {
            description: "We really promise we'll be in touch soon!",
          });
        }
      });
  }

  return (
    <>
      <div className="bg-white">
        <div className="relative overflow-hidden">
          <Popover as="header" className="relative">
            <div className="bg-gray-900 py-6">
              <div className="max-w-7xl mx-auto h-16 flex items-center justify-between px-6">
                <a href="/" className="flex items-center gap-3 text-gray-300">
                  <img className="h-12 w-auto" src="/images/logo.png" alt="Caspia Health Logo" />
                  <span className="text-lg">Caspia Health</span>
                </a>
                {/* Hamburger Icon for Mobile */}
                <div className="block lg:hidden">
                  <button
                    onClick={toggleNavbar}
                    className="text-gray-400 hover:text-white focus:outline-none focus:text-white"
                  >
                    {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
                  </button>
                </div>
                {/* Desktop Navigation Links */}
                <ul className="hidden lg:flex lg:items-center lg:space-x-6 text-gray-300 hover:text-white transition">
                  {navItems}
                </ul>
              </div>
              {/* Mobile Navigation Links */}
              <ul
                className={`lg:hidden mt-4 container px-6 ${
                  isOpen ? "flex flex-col" : "hidden"
                } text-gray-300 hover:text-white transition`}
              >
                {navItems}
              </ul>
            </div>
          </Popover>
          <main>
            <div className="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
              <div className="mx-auto max-w-7xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                  <div className="mx-auto max-w-md px-6 sm:max-w-2xl sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                    <div className="lg:py-24">
                      <h1 className="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                        <span className="block">A better way to</span>
                        <span className="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
                          manage your health
                        </span>
                      </h1>
                      <p className="intro-paragraph mb-3.5">
                        Caspia Health is an all-in-one health information management app.
                      </p>
                      <p className="intro-paragraph mb-3.5">
                        Currently, Caspia Health is only available to people whose doctors have invited them. Your
                        doctor will need to invite you. If you would like us to send an email to your doctor to inform
                        them about this platform, please provide their information{" "}
                        <a
                          className="underline"
                          href="https://forms.gle/aRmoD7xiJBUkeCFs6"
                          target="_blank"
                          rel="noreferrer"
                        >
                          here
                        </a>
                        .
                      </p>
                      <p className="intro-paragraph">
                        Please add your email address to this waiting list if you would like to be notified when Caspia
                        Health is open to the public.
                      </p>
                      <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                        Caspia Health is an all-in-one health information management app.
                      </p>
                      <div className="mt-10 sm:mt-12">
                        <form
                          onSubmit={(e) => handleSubmit(e, emailInputRef.current.value)}
                          className="sm:mx-auto sm:max-w-xl lg:mx-0"
                        >
                          <div className="sm:flex">
                            <div className="min-w-0 flex-1">
                              <label htmlFor="email" className="sr-only">
                                Email address
                              </label>
                              <input
                                ref={emailInputRef}
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                className="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                              />
                            </div>
                            <div className="mt-3 sm:mt-0 sm:ml-3">
                              <button
                                type="submit"
                                className="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
                              >
                                Join our waitlist
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                    <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0">
                      <img
                        className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative bg-white py-16 sm:py-24 lg:py-32">
              <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Unlock Your Data</p>
                <div className="mt-12">
                  <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    {features.map((feature) => (
                      <div key={feature.name} className="pt-6">
                        <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                          <div className="-mt-6">
                            <h3 className="mt-8 text-lg font-medium tracking-tight text-gray-900">{feature.name}</h3>
                            <p className="mt-5 text-base text-gray-500">{feature.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
