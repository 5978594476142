/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import { Popover } from "@headlessui/react";

import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";

import Footer from "../components/ui/Footer";

const NewsLetterPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navItems = (
    <>
      <li className="nav-item">
        <a href="https://mapper.caspiahealth.com/">Lifestyle Medicine Mapper</a>
      </li>
      <li className="nav-item">
        <Link to="/newsletter">Healthy Today Newsletter</Link>
      </li>
      <li className="nav-item">
        <a href="https://propatienttech.com/">For Physicians</a>
      </li>
      <li className="nav-item">
        <Link to="/login">Sign In</Link>
      </li>
    </>
  );

  const files = [
    {
      thumbnail: "/images/april.png",
      href: "https://caspiahealth-data.s3.amazonaws.com/April+2024+Caspia+Health+Newsletter.pdf",
    },
    {
      thumbnail: "/images/may.png",
      href: "https://caspiahealth-data.s3.amazonaws.com/May+2024+Caspia+Health+Newsletter.pdf",
    },
    {
      thumbnail: "/images/june.png",
      href: "https://caspiahealth-data.s3.amazonaws.com/June+2024+Caspia+Health+Newsletter.pdf",
    },
    {
      thumbnail: "/images/july.png",
      href: "https://caspiahealth-data.s3.amazonaws.com/July+2024+Caspia+Health+Newsletter.pdf",
    },
    {
      thumbnail: "/images/august.png",
      href: "https://caspiahealth-data.s3.amazonaws.com/August+2024+Caspia+Health+Newsletter.pdf",
    },
  ];

  useEffect(() => {
    // This will run once when the component mounts
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize the form fields after the script has loaded
      // eslint-disable-next-line
      window.fnames = new Array();
      // eslint-disable-next-line
      window.ftypes = new Array();
      window.fnames[0] = "EMAIL";
      window.ftypes[0] = "email";
      window.fnames[1] = "FNAME";
      window.ftypes[1] = "text";
      window.fnames[2] = "LNAME";
      window.ftypes[2] = "text";
      window.fnames[3] = "ADDRESS";
      window.ftypes[3] = "address";
      window.fnames[4] = "PHONE";
      window.ftypes[4] = "phone";
      window.fnames[5] = "BIRTHDAY";
      window.ftypes[5] = "birthday";
    };

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="bg-white flex flex-col min-h-screen">
      <Popover as="header" className="relative">
        <div className="bg-gray-900 py-6">
          <div className="max-w-7xl mx-auto h-16 flex items-center justify-between px-6">
            <a href="/" className="flex items-center gap-3 text-gray-300">
              <img className="h-12 w-auto" src="/images/logo.png" alt="Caspia Health Logo" />
              <span className="text-lg">Caspia Health</span>
            </a>
            {/* Hamburger Icon for Mobile */}
            <div className="block lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-400 hover:text-white focus:outline-none focus:text-white"
              >
                {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
              </button>
            </div>
            {/* Desktop Navigation Links */}
            <ul className="hidden lg:flex lg:items-center lg:space-x-6 text-gray-300 hover:text-white transition">
              {navItems}
            </ul>
          </div>
          {/* Mobile Navigation Links */}
          <ul
            className={`lg:hidden mt-4 container px-6 ${
              isOpen ? "flex flex-col" : "hidden"
            } text-gray-300 hover:text-white transition`}
          >
            {navItems}
          </ul>
        </div>
      </Popover>
      <main className="flex flex-grow">
        <div className="my-8 lg:my-16 mx-auto">
          <div id="mc_embed_shell">
            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
            <div id="mc_embed_signup" className="max-w-xs sm:max-w-md md:max-w-xl">
              <form
                action="https://caspiahealth.us22.list-manage.com/subscribe/post?u=816eb53031e14a2ed4b6ff74d&amp;id=24ca6e52d3&amp;f_id=0016cae1f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
              >
                <div id="mc_embed_signup_scroll">
                  <h2 className="text-lg font-semibold">Sign up for the Healthy Today newsletter!</h2>
                  <p className="mb-2">
                    Get a free, monthly Lifestyle Medicine newsletter full of motivational stories and resources to help
                    you achieve your health goals.
                  </p>
                  <div className="mc-field-group">
                    <label htmlFor="mce-EMAIL">
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required="" />
                  </div>
                  <div id="mce-responses" className="clear foot">
                    <div className="response hidden" id="mce-error-response"></div>
                    <div className="response hidden" id="mce-success-response"></div>
                  </div>
                  <div aria-hidden="true" className="absolute left-[-5000px]">
                    {/* real people should not fill this in and expect good
                    things - do not remove this or risk form bot signups */}
                    <input type="text" name="b_816eb53031e14a2ed4b6ff74d_24ca6e52d3" tabIndex="-1" defaultValue="" />
                  </div>
                  <p className="text-sm text-gray-500 mb-2">
                    By submitting this form, you are consenting to receive marketing emails from: Caspia Health.
                    {/* You can revoke your
                    consent to receive emails at any time by using the
                    SafeUnsubscribe® link, found at the bottom of every email.
                    Emails are serviced by Constant Contact. */}
                  </p>
                  <div className="optionalParent">
                    <div className="clear foot">
                      <input
                        type="submit"
                        name="subscribe"
                        id="mc-embedded-subscribe"
                        className="button"
                        value="Subscribe"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="grid md:grid-cols-3 gap-4">
                {files.map((file, index) => (
                  <a
                    key={index}
                    href={file.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h-auto border border-gray-300 shadow-sm hover:shadow-lg transition"
                  >
                    <img
                      src={file.thumbnail}
                      alt={`Thumbnail for ${file.pdf}`}
                      className="w-full h-full object-cover"
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default NewsLetterPage;
